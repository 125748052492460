import request from "@/utils/request";

//-------------------商户列表----------------------
export function Merchant(url, method, data) {
  return request({
    url: url,
    method: method,
    params: data
  });
}


//url: "/customer-service/lrMerchant/queryMerchantClassify"
//method: "post",
//查询商户分类
export function queryMerchantClassify(data) {
  return request({
    url: "/customer-service/lrMerchant/queryMerchantClassify",
    method: "post",
    data
  });
}