<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="搜索分类名称"
            class="search-input"
            v-model="classifyName"
            clearable
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="showCateDialog(1)"
                >创建分类</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <!-- <el-table-column type="selection"></el-table-column> -->
            <el-table-column label="分类名称" prop="classifyName"></el-table-column>
            <el-table-column label="商户数量" prop="merchantCount"></el-table-column>
            <el-table-column
              label="添加时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="操作" prop="operations">
              <template slot-scope="scope">
                <div class="flx-row ali-c" v-if="scope.row.classifyName!=='餐饮'">
                  <span
                    class="tip-text-info"
                    @click="showCateDialog(2, scope.row)"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <!-- <el-link type="danger" :underline="false" class="ml-10"> -->
                  <span class="ml-10 tip-text-delete" @click="deleteClassify(scope.row.id)">
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="search" @syncPageData="syncPageData"/>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showCate" :title="addFlag?'添加分类':'编辑分类'" @close="clearForm">
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="cate_form"
          :rules="rules"
          ref="cate_form"
        >
          <el-form-item label="分类名称" prop="category">
            <el-input
              placeholder="请输入类别名称"
              v-model="cate_form.category"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showCate = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request";
import Pagination from "@/components/Pagination";
import { queryMerchantClassify } from "@/api/service_center/merchants.js";
export default {
  name: "merchants_type",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      classifyName: "",
      classifyNameId: "",
      // 优惠券表格数据内容
      tableData: [],
      addFlag: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      showCate: false,
      cate_form: {
        category: ""
      },
      rules: {
        category: [{ required: true, message: "分类名称不能为空", trigger: "blur"}]
      }
    };
  },
  created() {
    this.search()
  },
  methods: {
    //获取所有类型
    initData(searchData = {}) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      data = Object.assign(data, searchData)
     queryMerchantClassify(data).then(res => {
        if(res.data.body && res.data.body.list) {
          let list = res.data.body.list.find(item => item.classifyName === "餐饮");
          res.data.body.list.splice(res.data.body.list.findIndex(item => item.classifyName === "餐饮"), 1);
          res.data.body.list.unshift(list)
          // console.log(res.data.body.list)
          this.tableData = res.data.body.list
          this.total = res.data.body.total
        }
      });
    },
    // 搜索事件
    search() {
      let data = {
        classifyName: this.classifyName
      };
      this.initData(data)
    },
    // 重置搜索关键词
    reset() {
      this.classifyName = "";
      this.initData();
    },
    clearForm() {
      this.cate_form.category = ""
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    deleteClassify(id) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          request({
            url:"/customer-service/lrMerchant/delMerchantClassify/" + id,
            method: "delete"
          }).then(() => {
            this.initData()
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    showCateDialog(type, row) {
      this.addFlag = type == 1 ? true : false;
      if(row) {
        this.classifyNameId = row.id ? row.id : "";
        this.cate_form.category = row.classifyName
      }
      this.showCate = true;
    },
    submit() {
      this.$refs['cate_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.operateMerchants()
      })
    },
    operateMerchants() {
      let data = this.addFlag
        ? {
            url: "/customer-service/lrMerchant/addMerchantClassify",
            method: "post",
            data: {
              classifyName: this.cate_form.category
            }
          }
        : {
            url: "/customer-service/lrMerchant/updateMerchantClassify",
            method: "post",
            data: {
              classifyName: this.cate_form.category,
              id: this.classifyNameId
            }
          };
        
      request(data).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
          this.initData()
          this.showCate = false
          this.classifyName = ""
        }
      });
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
